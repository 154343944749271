@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
  color: #2c2c2c !important;
}

// General
.d-flex {
  display: flex;
  padding: 0 2px;

  &.align-center {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
  }

  &.column {
    flex-direction: column;
  }
}

.d-flex>.double-filters-container>.react-datepicker-wrapper {
  margin: 0;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.ml-8 {
  margin-left: 8px;
}

.ml-16 {
  margin-left: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.align-self-right {
  align-self: flex-end;
}

.page-filters {
  padding-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.address-error-container {
  display: flex;
  flex-direction: column;
}

.address-error-container>p:first-child {
  margin: 4px 0 4px 0;
  font-weight: bold;
}

.address-error-container>p:nth-child(2) {
  margin: 8px 0 8px 8px;
}

.toast-support-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgb(39, 39, 39);
  color: rgb(39, 39, 39);
  text-decoration: none;
  border-radius: 4px;
  padding: 8px 12px;
  width: 205px;
  box-shadow: 0px 2px 2px #6666667e;
  margin: 12px 0 8px 0;
}

.toast-support-button p {
  margin: 0 0 0 12px;
}

// Login
.register-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.register-form {
  width: 430px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
}

.register-logo {
  width: 130px;
  height: 58.5px;
  background: url(../../images/logo-hub.png) no-repeat center center / contain;
}

.register-title {
  font-size: 40px;
  font-weight: 500;
  margin: 16px 0 24px 0;
}

.login-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: white;
}

.password-strength-bar {
  margin-top: 4px;
  margin-bottom: 20px;
}

.credentials {
  width: 400px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.credentials p {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;
}

.credentials p span:first-child {
  margin-bottom: 8px;
}

.footer-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px 0 24px 0;
  color: #3f3f417a;
}

.login-logo {
  width: 130px;
  height: 58.5px;
  background: url(../../images/logo-hub.png) no-repeat center center / contain;
  margin-bottom: 16px;
}

.forgot-password-link {
  font-weight: bold;
  width: auto;
  text-decoration: underline;
  align-self: flex-start;
  color: #00a9a8;
  font-size: 12px;
  margin: 16px 0 32px 0;
  cursor: pointer;
  transition: all 700ms;
}

//Pages
.pages-container {
  padding: 48px 32px 32px 32px;
  width: calc(100% - 64px);
  height: calc(100vh - 171px);
  overflow-y: auto;
}

.pages-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.new-order {
    justify-content: flex-start;
    margin-bottom: 24px;
  }
}

.pages-header p {
  font-size: 44px;
  font-weight: 500;
  margin: 0;
}

.mr-16 {
  margin-right: 20px !important;
}

.with-return-container {
  display: flex;
  margin-bottom: 20px;
  width: 178px;
  justify-content: space-between;
  align-items: flex-end;
}

// Header
.header-container {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw - 40px);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #fff;
  z-index: 1;
  height: 51px;
}

.header-container div {
  display: flex;
  align-items: center;
}

.logo {
  width: 175px;
  height: 80px;

  &.mb-24 {
    margin-bottom: 24px;
  }

  &.carioca {
    background: url(../../images/logo-carioca.png) no-repeat center center / contain;
  }

  &.boxhub {
    background: url(../../images/logo-hub.png) no-repeat center center / contain;
  }
}

.sidebar-controller {
  width: 24px;
  height: 24px;
  background: url(../../images/controller.svg) no-repeat center center / contain;
  position: relative;
  padding: 2px;
  margin-left: 70px;
  cursor: pointer;

  &.right {
    animation: controller-right 0.6s linear;
    transform: scaleX(-1);
  }

  &.left {
    animation: controller-left 0.6s linear;
    transform: scaleX(1);
  }
}

@keyframes controller-right {
  from {
    transform: scaleX(1);
  }

  to {
    transform: scaleX(-1);
  }
}

@keyframes controller-left {
  from {
    transform: scaleX(-1);
  }

  to {
    transform: scaleX(1);
  }
}

.profile-content {
  cursor: pointer;
}

.settings-icon {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 50%;
}

// Sidebar
.sidebar-container {
  position: absolute;
  top: 91px;
  left: 0;
  background-color: #fff;
  width: 20%;
  height: calc(100vh - 132px);
  box-shadow: 0 1px 4px -1px rgb(0 0 0 / 15%);
  margin: 0;
  padding: 41px 0 0 0;
  display: flex;
  flex-direction: column;

  &.show-sidebar {
    width: 20%;
    animation: show-sidebar 0.6s ease;
  }

  &.hide-sidebar {
    width: 79px;
    animation: hide-sidebar 0.6s ease;
  }
}

.hub-dropdown {
  width: 90%;
  min-width: 300px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  position: absolute;
  left: 5%;
  top: 80px;
  padding: 6px 0;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000044;

  &.mobile {
    top: 60px;
    min-width: 0px;
  }
}

.dropdown ul,
.hub-dropdown ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
  width: 100%;
}

.dropdown ul li a,
.hub-dropdown ul li {
  padding: 10px 6px;
  font-size: 14px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown ul li:hover,
.dropdown ul a:hover {
  background-color: rgb(63, 63, 63);
}

.hub-dropdown ul li:hover {
  background-color: rgb(228, 228, 228);
}

.arrow-icon {
  width: 24px;
  height: 24px;
  background: url(../../images/profile-arrow.svg) no-repeat center center / contain;
  margin-left: 4px;
}

.hub-filter {
  width: calc(100% - 48px);
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 24px 40px 24px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;

  &.mobile {
    margin: 8px 24px 24px 24px;
  }
}

.gmt-select {
  margin: auto 24px 0 24px;
}

.support-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgb(39, 39, 39);
  color: rgb(39, 39, 39);
  text-decoration: none;
  border-radius: 4px;
  padding: 8px 12px;
  width: calc(100% - 72px);
  margin: 24px;
  box-shadow: 0px 2px 2px #6666667e;

  &.inactive {
    padding: 8px 12px;
    width: calc(100% - 48px);
    margin: auto 12px 24px 12px;
  }

  &.mb-dev {
    margin: 24px 24px 56px 24px;
  }

  &.mb-dev.inactive {
    margin: 24px 12px 56px 12px;
  }
}

.support-button:hover {
  animation: support-change .1s linear;
  background-color: rgb(39, 39, 39);
  color: rgb(235, 235, 235);

  &.support-icon {
    background: url(../../images/support-hover.png) center center / cover;
  }
}

.support-button p {
  margin: 0 0 0 12px;
}

.support-icon {
  background: url(../../images/support.png) center center / contain;
  width: 24px;
  height: 24px;
}

.support-button:hover>.support-icon {
  background: url(../../images/support-hover.png) center center / contain;
}

@keyframes support-change {
  0% {
    background-color: #fff;
    color: rgb(39, 39, 39);
  }

  100% {
    background-color: rgb(39, 39, 39);
    color: rgb(235, 235, 235);
  }
}

.hub-selected {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes hide-sidebar {
  from {
    width: 20%;
  }

  to {
    width: 79px;
  }
}

@keyframes show-sidebar {
  from {
    width: 79px;
  }

  to {
    width: 20%;
  }
}

.sidebar-container li {
  display: block;
}

.sidebar-container li:not(:first-child) {
  margin: 4px 0;
}

.sidebar-container>li.hub-name {
  margin: 0 0 40px 32px;
  font-size: 24px;
  font-weight: 500;
  list-style: none;
}

.sidebar-link {
  display: flex;
  align-items: center;
  padding: 16px 0 16px 26px;
  text-decoration: none;
  color: #3f3f41;
  width: calc(100% - 27px);

  &.active {
    border-right: 3px solid #00a9a8;
    background-color: rgba(120, 47, 250, 0.1);
    color: #00a9a8;
    animation: active-sidebar-link 0.8s ease;
    opacity: 0.37;
  }
}

.sidebar-link:not(:last-child) {
  margin-bottom: 32px;
}

@keyframes active-sidebar-link {
  from {
    background-color: #fff;
    border-right: 3px solid #fff;
  }

  to {
    background-color: rgba(120, 47, 250, 0.1);
    color: #00a9a8;
    border-right: 3px solid #00a9a8;
  }
}

.sidebar-image {
  width: 24px;
  height: 24px;
  background-color: #3f3f41;

  &.orders {
    -webkit-mask-image: url(../../images/orders-active.svg);
    mask-image: url(../../images/orders-active.svg);
  }

  &.transfers {
    -webkit-mask-image: url(../../images/transfers.svg);
    mask-image: url(../../images/transfers.svg);
  }

  &.users {
    -webkit-mask-image: url(../../images/users.svg);
    mask-image: url(../../images/users.svg);
  }

  &.sellers {
    -webkit-mask-image: url(../../images/sellers.svg);
    mask-image: url(../../images/sellers.svg);
  }
}

.sidebar-link:hover>.sidebar-image,
.sidebar-link.active>.sidebar-image {
  background-color: #00a9a8;
}

.sidebar-title {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  margin-left: 16px;
}

.sidebar-link:hover,
.sidebar-link:focus {
  text-decoration: none;
  color: #00a9a8;
}

.mobile-sidebar {
  display: none;
}

// Pages
.pages {
  padding-top: 91px;
  padding-left: 20%;
  width: 80%;
  position: relative;
  height: calc(100vh - 91px);

  &.with-sidebar {
    width: 80%;
    padding-left: 20%;
    animation: with-sidebar 0.6s ease;
  }

  &.without-sidebar {
    width: calc(100vw - 79px);
    padding-left: 79px;
    animation: without-sidebar 0.6s ease;
  }
}

@keyframes without-sidebar {
  from {
    width: 80%;
    padding-left: 20%;
  }

  to {
    width: calc(100vw - 79px);
    padding-left: 79px;
  }
}

@keyframes with-sidebar {
  from {
    width: calc(100vw - 79px);
    padding-left: 79px;
  }

  to {
    width: 80%;
    padding-left: 20%;
  }
}

// Orders
.orders-filters {
  display: flex;
  align-items: center;
  margin-top: 30px;
  flex-wrap: wrap;
  padding-bottom: 4px;
  border-bottom: 1px solid #c4c4c4;
}

// Loading
.fallback-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1600;
}

.loading-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.register {
    height: 627px;
  }

  &.recover {
    height: 211px;
  }
  &.modal-icon {
    width: 180px;
  }
}

.loading-icon {
  width: 150px;
  height: 150px;
  background: url(../../images/loading.svg) no-repeat center center / contain;

  &.mini {
    width: 35px;
    height: 35px;
  }
}

.loading-dropdown {
  width: 30px;
  height: 30px;
  background: url(../../images/loading.svg) no-repeat center center / contain;
}

.success-response {
  display: none;
  font-size: 28px;
}

// TextInput
.input {
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
  width: calc(100% - 2px);

  &.no-mr {
    margin-right: 0;
  }

  &.no-mb {
    margin-bottom: 0;
  }
}

.input-inner::placeholder {
  /* Firefox, Chrome, Opera */
  color: #969696;
}

.input-inner:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #969696;
}

.input-inner::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #969696;
}

.input-number {
  line-height: normal !important;
  width: calc(100% - 32px);
  border: 1px solid #0000001f;
  border-radius: 4px;
  color: #3f3f41;
  box-shadow: 0 0 0 0 rgba(63, 63, 63, 0.1);
  font-size: 16px;
  transition: 0.3s;
  padding: 16px;
  position: relative;
}

.input-number-count {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.number-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;

  &.plus {
    background: url(../../images/plus-icon.svg) no-repeat center center / contain;
    margin-left: 12px;
  }

  &.less {
    background: url(../../images/less-icon.svg) no-repeat center center / contain;
    margin-right: 12px;
  }
}

.input-inner {
  line-height: normal !important;
  width: calc(100% - 32px);
  border: 1px solid #0000001f;
  outline: none;
  border-radius: 4px;
  color: #3f3f41;
  box-shadow: 0 0 0 0 rgba(63, 63, 63, 0.1);
  font-size: 16px;
  background-color: #f7f7f7;
  transition: 0.3s;

  &.text {
    padding: 16px;
  }

  &.text.filled,
  &:active,
  &:focus {
    padding: 25px 16px 7px;
  }

  &:hover {
    box-shadow: 0 0 0 2px rgba(63, 63, 63, 0.1);
  }

  &:active,
  &:focus {
    border-color: rgba(250, 205, 72, 0.8);
    box-shadow: 0 0 0 2px rgba(250, 205, 72, 0.2);
  }
}

.filled+.input-label {
  opacity: 1;
  top: 8px;
  font-size: 12px;
}

.input-inner:focus+.input-label {
  opacity: 1;
  top: 8px;
  font-size: 12px;
}

.input-label {
  position: absolute;
  top: 16px;
  left: 16px;
  opacity: 0;
  transition: 0.3s;
  color: #3f3f41b8;
  cursor: text;
  pointer-events: none;
  width: calc(100% - 32px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.input-button {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 32px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid black;
  cursor: pointer;
}

.input-icon {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &.interrogation {
    background: url(../../images/interrogation-icon.svg) no-repeat center center / contain;
  }
}

.icon-visibility-on {
  background: url(../../images/icon-visibility-on.svg) no-repeat center center / contain;
}

.icon-visibility-off {
  background: url(../../images/icon-visibility-off.svg) no-repeat center center / contain;
}

.input-error {
  background-color: rgba(255, 0, 0, 0.386) !important;
  animation: shake-error 1s linear;
}

.google-input__control {
  line-height: normal !important;
  width: 100% !important;
  border: 1px solid #0000001f;
  border-color: #0000001f !important;
  outline: none !important;
  border-radius: 4px !important;
  color: #3f3f41 !important;
  box-shadow: 0 0 0 0 rgba(63, 63, 63, 0.1) !important;
  font-size: 16px !important;
  background-color: #f7f7f7 !important;
  transition: 0.3s !important;
  padding: 7px !important;
  background-color: transparent !important;
  cursor: text !important;

  &:hover {
    border: 1px solid #0000001f;
    box-shadow: 0 0 0 2px rgba(63, 63, 63, 0.1);
  }

  &:active,
  &:focus {
    border-color: rgba(250, 205, 72, 0.8);
    box-shadow: 0 0 0 2px rgba(250, 205, 72, 0.2);
  }
}

.google-input__control--is-focused {
  border-color: rgba(250, 205, 72, 0.8) !important;
  box-shadow: 0 0 0 2px rgba(250, 205, 72, 0.2) !important;
}

@keyframes shake-error {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

// Pagination
.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.pagination-li {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #3f3f4123;
  border-bottom: 1px solid #3f3f4123;
  cursor: pointer;
}

.pagination-li.disabled {
  cursor: not-allowed !important;
}

.pagination-li.disabled .pagination-link {
  cursor: not-allowed !important;
}

.pagination-li.disabled .pagination-link:hover {
  color: #2c2c2c;
  background-color: transparent;
}

.pagination-li:nth-child(2n) {
  border-right: 1px solid #3f3f4123;
  border-left: 1px solid #3f3f4123;
}

.pagination-li-border-left {
  border-left: 1px solid #3f3f4123;
}

.pagination-li-border-right {
  border-right: 1px solid #3f3f4123;
}

.pagination-link {
  text-decoration: none;
  color: #2c2c2c;
  padding: 12px 16px;
}

.pagination-link:hover {
  color: white;
  background-color: rgb(0, 169, 168);
}

.pagination-active {
  background-color: rgb(0, 169, 168);
  border: 1px solid rgb(0, 169, 168);
}

.pagination-active>.pagination-link {
  color: white;
}

// ReactSelect
.reactSelect {
  margin-right: 20px;
  margin-bottom: 20px;
}

.reactSelectUser {
  margin-right: 20px;
  margin-bottom: 20px;
}

.reactSelectUser>.reactSelect__control {
  line-height: normal !important;
  width: 762px;
  padding: 6px 0 6px 8px;
  background-color: transparent !important;
  border: 1px solid rgba(63, 63, 65, 0.2);
  border-radius: 4px !important;
  color: #fff;
  box-shadow: 0 0 0 0 rgba(63, 63, 63, 0.1);
  font-size: 16px;
  cursor: text !important;
  height: 52px;
  overflow: auto;
}

.reactSelect>.reactSelect__control {
  line-height: normal !important;
  width: 250px;
  padding: 6px 0 6px 8px;
  background-color: transparent !important;
  border: 1px solid rgba(63, 63, 65, 0.2);
  border-radius: 4px !important;
  color: #fff;
  box-shadow: 0 0 0 0 rgba(63, 63, 63, 0.1);
  font-size: 16px;
  cursor: text !important;
  height: 52px;
  overflow: auto;
}

.reactSelect__control--is-focused,
.reactSelect__control.reactSelect__control--menu-is-open:hover {
  border-color: rgba(250, 205, 72, 0.8) !important;
  box-shadow: 0 0 0 2px rgba(250, 205, 72, 0.2) !important;
}

.reactSelect__control:hover {
  box-shadow: 0 0 0 2px rgba(63, 63, 63, 0.1) !important;
  border-color: rgba(63, 63, 65, 0.2) !important;
}

.reactSelect__indicators {
  cursor: pointer !important;
}

.reactSelect__multi-value {
  background-color: #00a9a8 !important;
  border-radius: 4px !important;
}

.reactSelect__multi-value__label {
  color: #fff !important;
  font-size: 16px !important;
  border-radius: 4px !important;
}

.reactSelect__multi-value__remove {
  color: #fff !important;
  border-radius: 4px !important;
  padding-left: 8px !important;
}

.reactSelect__multi-value__remove:hover {
  background-color: transparent !important;
}

.reactSelect__multi-value__remove svg {
  width: 17px;
  height: 17px;
  cursor: pointer !important;
}

.reactSelect__placeholder {
  color: #000 !important;
}

// CustomSelector
select::-ms-expand {
  display: none;
}

select.minimal:focus {
  background-image: linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}

.custom-select {
  display: flex;
  position: relative;

  &.with-margin {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  &.only-mr {
    margin-right: 20px;
  }
}

.custom-select:after {
  content: "";
  background-image: url(../../images/profile-arrow.svg);
  background-position: center right;
  background-size: 29px;
  width: 33px;
  height: 18px;
  background-repeat: no-repeat;
  font: 17px "Consolas", monospace;
  color: #333;
  right: 5px;
  top: 16px;
  padding: 0 0 2px;
  border-left: 1px solid rgb(208, 208, 208);
  position: absolute;
  pointer-events: none;
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  width: 100%;
  border: 1px solid rgba(63, 63, 65, 0.2);
  box-shadow: 0 0 0 0 rgba(63, 63, 63, 0.1);
  border-radius: 4px;
  padding: 17px 36px 16px 16px;
  cursor: pointer;
  font-size: 16px;
  color: #3f3f417a;
  outline: none;

  &.text {
    padding: 15px;
  }

  &:hover {
    box-shadow: 0 0 0 2px rgba(63, 63, 63, 0.1);
  }

  &:active,
  &:focus {
    border-color: rgba(250, 205, 72, 0.8);
    box-shadow: 0 0 0 2px rgba(250, 205, 72, 0.2);
  }
}

.filter-select:hover {
  box-shadow: 0 0 0 2px rgba(63, 63, 63, 0.1);
}

.select .options {
  color: #3f3f41;
}

.select .required-icon {
  position: absolute;
  top: 14px;
  right: 32px;
}

// PageNumberSelect
.page-number-select {
  display: flex;
  position: relative;
  margin-right: 20px;
  margin-left: auto;
}

.page-number-select.transfer {
  margin: 12px 0 12px auto;
}

.page-number-select:after {
  content: "";
  background-image: url(../../images/profile-arrow.svg);
  background-position: center right;
  background-size: 29px;
  width: 33px;
  height: 18px;
  background-repeat: no-repeat;
  font: 17px "Consolas", monospace;
  color: #333;
  right: 5px;
  top: 14px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
}

.number-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: 16px 36px 16px 16px;
  cursor: pointer;
  font-size: 16px;
  color: #3f3f417a;
  outline: none;
}

// Button
.button-container {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 0;
  align-items: center;
  position: relative;
  outline: none;
  box-shadow: 0px 2px 2px #00000029;

  &.yellow {
    background-color: #facd48;
  }

  &.blue {
    background-color: #00a9a8;
  }

  &.gray {
    background-color: #bababa;
    margin-right: 16px;
  }

  &.disabled-button {
    cursor: not-allowed !important;
    opacity: 0.7;
  }

  &.align-center {
    align-self: center;
    margin-bottom: 20px;
  }

  &.mr-16 {
    margin-right: 16px;
  }
}

.button-container:disabled,
.button-container[disabled] {
  opacity: .6;
  cursor: not-allowed;
}

.button-inner {
  display: inline-block;
  padding: 10px 0;
  flex-grow: 2;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
  font-family: "Roboto";
  font-weight: 500;
  position: relative;

  &.yellow {
    color: #3f3f41;
  }

  &.blue {
    color: #fff;
  }
}

.button-inner:hover,
.button-inner:focus {
  text-decoration: none;
}

.button-inner>input {
  opacity: 0;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  padding: 6px 0;
}

.button-inner>p {
  margin: 0;
}

input[type=file],
/* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.multi-dropdown {
  width: 130px;
  background-color: #facd48;
  color: #000;
  position: absolute;
  right: 0;
  top: 38px;
  padding: 6px 0;
  z-index: 1;
}

.multi-dropdown ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
  width: 100%;
}

.multi-dropdown ul li {
  padding: 10px 6px;
  font-size: 14px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multi-dropdown ul li:hover {
  background-color: #dfb228;
}

// List
.list {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  list-style: none;
}

.list-text {
  font-size: 12px;
  color: #3f3f41b8;
}

.list-text.link {
  cursor: pointer;
}

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid rgba(63, 63, 65, 0.1);
  position: relative;
  width: 100%;
}

.list-item:nth-child(even) {
  background-color: #efefef;
}

.list-item:first-child,
.list-item:last-child {
  border-bottom: none;
}

.list-item:hover .list-options {
  right: 0;
  opacity: 1;
}

.list-head {
  padding: 4px 0;
  color: #acacac;
  font-weight: 500;
  font-size: 14px;
}

.list-head>.list-cell {
  border-right: none !important;
  border-left: none !important;
}

.list-cell {
  padding: 8px 8px;
  position: relative;
}

.list-cell p {
  margin: 0;
  font-size: 16px;
}

.list-cell.client>p:first-child {
  font-size: 16px;
}

.list-cell.client>p:nth-child(2) {
  font-size: 14px;
  margin: 6px 0 6px 6px;
}

.list-cell.client>p:nth-child(3) {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  margin: 6px 0 6px 6px;
}

.list-cell.client>p:nth-child(3)>span:nth-child(1) {
  display: block;
  font-weight: 500;
  margin-bottom: 3px;
}

.list-cell.client>p:nth-child(3)>span:nth-child(2) {
  font-size: 12px;
}

.vtex-id {
  font-size: 10px !important;
}

.list-cell.client>div:nth-child(2) {
  font-size: 14px;
  margin-top: 6px;
}

.list-cell.hub>p:first-child {
  font-size: 14px;
}

.list-cell.hub>p:nth-child(2),
.list-cell.hub>p:nth-child(4) {
  font-size: 16px;
  font-weight: 500;
}

.list-cell.hub>div:nth-child(2) {
  font-size: 14px;
  margin-top: 6px;
}

.list-cell.hub>p:nth-child(3) {
  font-size: 14px;
}

.list-cell.double-value {
  display: flex;
  flex-direction: column;
}

.list-options {
  padding: 5px 0 5px 20px;
  background: white;
  position: absolute;
  right: -20px;
  opacity: 0;
  transition: 0.3s;
}

.list-options-item {
  margin-left: 10px;
  display: block;
  width: 24px;
  height: 24px;
  background: white;
  border: 1px solid rgba(63, 63, 65, 0.32);
  border-radius: 4px;
  float: left;
  cursor: pointer;
}

.list-options-item:hover {
  border-color: #facd48;
}

.list-footer {
  padding: 24px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.actions-button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 15px 22px 15px;
  width: 0;
  height: 0;
  font-size: 30px;
  font-weight: 500;
  background-color: #C4C4C4;
  border-radius: 4px;
  cursor: pointer;
}

.empty-list {
  width: 100%;
  height: calc(100vh - 379px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.empty-image {
  width: 380px;
  height: 270px;
  background: url("../../images/empty-list.svg") no-repeat center center / contain;
}

.empty-list p {
  font-size: 18px;
}

// Orders
.list-orders {
  margin-top: 32px;
}

.list-orders .list-cell:nth-child(1) {
  width: 5%;
  display: flex;
  justify-content: center;
}

.list-orders .list-cell:nth-child(1) {
  width: 5%;
  display: flex;
  justify-content: center;
}

.list-orders .list-cell:nth-child(2) {
  width: 11%;
}

.list-orders .list-cell:nth-child(3) {
  width: 11%;
}

// .list-orders .list-cell:nth-child(4) {
//   width: 7%;
// }
.list-orders .list-cell:nth-child(4) {
  width: 15%;
}

.list-orders .list-cell:nth-child(5) {
  width: 12%;
}

.list-orders .list-cell:nth-child(6) {
  width: 30%;
  border-right: 1px solid rgba(63, 63, 65, 0.1);
  border-left: 1px solid rgba(63, 63, 65, 0.1);
}

.list-orders .list-cell:nth-child(7) {
  width: 10%;
}

.list-orders .list-cell:nth-child(8) {
  width: 5%;
}

.store-column>div {
  margin: 0 8px;
  padding-bottom: 8px;
}

.store-column>div:not(:first-child) {
  border-top: 2px solid rgba(63, 63, 65, 0.1);
  padding-top: 8px;
}

.store-title {
  font-weight: 500;
}

.store-description {
  font-size: 14px !important;
}

.store-item {
  display: flex;
  align-items: center;
}

.store-info {
  margin-bottom: 12px;
  margin-right: 4px;
}

.store-item>div:nth-child(2) {
  margin-left: auto;
  align-self: flex-start;
}

.store-item>div:nth-child(3) {
  margin-left: 8px;
  align-self: flex-start;
}

.item-description {
  width: 80%;
  font-size: 14px !important;
  margin: 4px 0 12px 12px !important;

  &.alone {
    margin: 16px 0 16px 0 !important;
  }
}

.new-order-sections {
  display: flex;
  flex-wrap: wrap;
}

.new-order-section.goods:nth-child(1) {
  width: 80%;
}

.new-order-section.goods:nth-child(2) {
  width: 80%;
}

.new-order-section.goods:nth-child(3) {
  width: 80%;
}

.new-order-section.goods:nth-child(4) {
  width: 45%;
}

.new-order-section.goods:nth-child(5) {
  width: 55%;
}

.new-order-section.goods:nth-child(6) {
  width: 80%;
}

.new-order-section.goods:nth-child(7) {
  width: 30%;
}

.new-order-section.foods:nth-child(1) {
  width: 80%;
}

.new-order-section.foods:nth-child(2) {
  width: 30%;
}

.new-order-section.foods:nth-child(3) {
  width: 50%;
}

.new-order-section.foods:nth-child(4) {
  width: 80%;
}

.new-order-section.foods:nth-child(5) {
  width: 60%;
}

.new-order-section.foods:nth-child(6) {
  width: 60%;
}

.new-order-section:nth-child(4)>.new-order-inputs>div:nth-child(2) {
  display: flex;
}

.new-order-section>.new-order-inputs.address {
  padding-left: 20px;
  border-left: 1px solid #c4c4c4;
}

.new-order-section.address>.new-order-title {
  margin-left: 20px;
}

.new-order-title {
  font-size: 22px;
  margin: 4px 0 20px 0;
}

.foods-items,
.goods-items {
  flex-direction: column;
  position: relative;
}

.new-order-inputs>div {
  display: flex;
  align-items: center;

  &.justify-end {
    justify-content: flex-end;
  }

  &.mb-20 {
    margin-bottom: 20px;
  }
}

.new-order-inputs>div>.d-flex {
  display: flex;
  width: 100%;
  position: relative;

  &.column {
    flex-direction: column;
  }

  &.column:not(:first-child) {
    padding-top: 20px;
    border-top: 1px solid rgb(198, 198, 198);
  }
}

.new-order-inputs>div>.d-flex.column>div {
  display: flex;
  align-items: center;
}

.new-order-button {
  display: flex;
  justify-content: flex-end;
}

.itens-remove {
  position: absolute;
  right: -60px;
  border-radius: 50%;
  background-color: rgb(252, 63, 63);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;

  &.goods {
    top: calc(50% - 14px);
  }

  &.foods {
    top: calc(50% - 26px);
  }
}

.x-divisor {
  margin-bottom: 20px;
  margin-right: 20px;
}

.timer-controller-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer-controller-info>div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
}

.timer-controller-info>div>div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.timer-controller-info>div>div>p {
  margin-right: 16px;
  font-weight: 500;
}

// ReactSignature
.signatureCanvas {
  border: 1px solid black;
  width: calc(100% - 2px);
  height: 350px;
  border-radius: 4px;
}

// TextSelect
.text-select {
  margin-bottom: 20px;
  width: 100%;
}

.text-select .select__control {
  padding: 7px 6px !important;
  background-color: transparent !important;
  cursor: text !important;
  box-shadow: 0 0 0 0 rgb(63 63 63 / 10%) !important;
  border: 1px solid #0000001f !important;

  &:hover {
    box-shadow: 0 0 0 2px rgba(63, 63, 63, 0.1) !important;
  }
}

.text-select .select__control--is-focused {
  border-color: rgba(250, 205, 72, 0.8) !important;
  box-shadow: 0 0 0 2px rgba(250, 205, 72, 0.2) !important;
}

// Transfers
.list-transfers {
  margin-top: 32px;
}

.list-transfers .list-cell:nth-child(1) {
  width: 5%;
}

.list-transfers .list-cell:nth-child(2) {
  width: 11%;
}

.list-transfers .list-cell:nth-child(3) {
  width: 11%;
}

.list-transfers .list-cell:nth-child(4) {
  width: 17%;
}

.list-transfers .list-cell:nth-child(5) {
  width: 17%;
}

.list-transfers .list-cell:nth-child(6) {
  width: 26%;
  border-right: 1px solid rgba(63, 63, 65, 0.1);
  border-left: 1px solid rgba(63, 63, 65, 0.1);
}

.list-transfers .list-cell:nth-child(7) {
  width: 12%;
}

.list-transfers .list-cell:nth-child(8) {
  width: 5%;
}

// Users
.list-users {
  margin-top: 32px;
}

.list-users .list-item {
  align-items: center !important;
}

.delete-icon {
  background: url(../../images/delete-icon.png) center center / contain;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

// .list-users .list-cell:nth-child(1) {
//     width: 5%;
// }
.list-users .list-cell:nth-child(1) {
  width: 13%;
}

.list-users .list-cell:nth-child(2) {
  width: 16%;
}

.list-users .list-cell:nth-child(3) {
  width: 27%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-users .list-cell:nth-child(4) {
  width: 10%;
}

.list-users .list-cell:nth-child(5) {
  width: 10%;
}

.list-users .list-cell:nth-child(6) {
  width: 5%;
}

.new-user-section,
.profile-section {
  width: 780px;
  display: flex;
  flex-wrap: wrap;
}

.new-user-section>.input,
.new-user-section>.custom-select,
.profile-section>.input,
.profile-section>.custom-select {
  width: calc(50% - 20px) !important;
}

.new-user-button,
.profile-button {
  width: 762px;
  display: flex;
  justify-content: flex-end;
}

.profile-section:nth-child(6) {
  margin-top: 12px;
}

// ImageUploader
.image-uploader {
  margin-bottom: 32px;
  display: flex;
}

.edit-photo-container {
  display: flex;
  align-items: flex-end;
}

.react-crop {
  max-width: 100%;
}

.crop-image {
  max-width: 96px;
  max-height: 96px;
}

// FileUploader
.file-uploader {
  margin: 12px 0 32px 0;
  background: #00a9a8;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 4px;
  color: #fff;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  width: 140px;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 500;
  box-shadow: 0px 2px 2px #00000029;
}

.file-uploader input.file {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 39px;
  cursor: pointer;
}

.file-uploader span {
  font-size: 13px;
}

.file-name {
  position: absolute;
  left: 185px;
  top: 0;
  width: 350px;
  height: 39px;
  display: flex;
  align-items: center;
  color: #2c2c2c;
}

// DatePicker
.double-filters-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.react-datepicker-wrapper {
  width: 250px !important;
  margin-right: 20px;
  margin-bottom: 20px;
}

.datePicker {
  background-color: transparent;
  border: 1px solid rgba(63, 63, 63, 0.2);
  box-shadow: 0 0 0 0 rgba(63, 63, 63, 0.1);
  outline: none;
  border-radius: 4px;
  width: 100%;
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &.w-120 {
    padding: 12px;
    font-size: 16px;
    font-family: 'Roboto';
    width: 90px;
    margin-left: 24px;
  }
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: contrast(0%);
  width: 20px;
  height: 20px;
}

.datepicker-text {
  margin: 0 20px 20px 0;
}

.datePicker:hover {
  box-shadow: 0 0 0 2px rgba(63, 63, 63, 0.1);
}

.datePicker:active,
.datePicker:focus {
  border-color: rgba(250, 205, 72, 0.8);
  box-shadow: 0 0 0 2px rgba(250, 205, 72, 0.2);
}

.datePicker:active,
.datePicker:focus {
  background: transparent;
}

.datePicker p {
  font-size: 16px;
  font-family: 'Roboto';
  margin: 0px;
  padding: 0 20px 0 5px;
}

.datePickerFilter p {
  margin: 0 8px 0 0;
}

.datePickerHeader {
  padding: 5px 8px;
  display: flex;
  justify-content: space-between;
}

.datePickerHeader p {
  font-size: 19px;
  margin: 0;
}

.datePickerHeaderMonth {
  padding: 0 5px 0 0;
}

.datePickerHeaderYear {
  padding: 0 0 0 5px;
}

.datePickerButton {
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.react-datepicker {
  font-size: 12px !important;
  min-width: 230px;
}

.react-datepicker__month-container {
  min-width: 230px;
}

.react-datepicker__current-month {
  font-size: 12px !important;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 2px !important;
  padding: 5px !important;
  width: 25px !important;
}

// Checkbox
.checkbox {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid rgb(172, 172, 172);
  padding: 2px;
  height: 14px;
  width: 14px;
  display: block;
  border-radius: 4px;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox img {
  position: absolute;
  top: -8px;
}

.checkbox-checkmark {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border-radius: 4px;
  transition: 0.3s;
}

.checkbox:hover input~.checkbox-checkmark {
  background-color: rgb(213, 213, 213);
}

.checkbox input:checked~.checkbox-checkmark {
  background-color: #00a9a8;
}

.checkbox input:checked~.checkbox {
  border: 2px solid #00a9a8;
}

.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked~.checkbox-checkmark:after {
  display: block;
}

// Sellers
.list-sellers {
  margin-top: 32px;
}

.list-sellers .list-item {
  align-items: center !important;
}

.list-sellers .list-cell:nth-child(1) {
  width: 25%;
}

.list-sellers .list-cell:nth-child(2) {
  width: 15%;
}

.list-sellers .list-cell:nth-child(3) {
  width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-sellers .list-cell:nth-child(4) {
  width: 10%;
}

.list-sellers .list-cell:nth-child(5) {
  width: 10%;
}

.cep-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

// Modal
.modal {
  position: fixed;
  z-index: 1500;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-adjust {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.modal-header {
  width: 100%;
  text-align: center;
  position: relative;
}

.invoice-button {
  position: absolute;
  top: 0;
  right: 0;
}

.modal-header p {
  font-weight: 500;
  text-align: start;
  margin: 0;
  color: #222222;
}

.modal-header p:first-child {
  font-size: 40px;
}

.modal-header p:nth-child(2) {
  font-size: 18px;
  margin-top: 8px;
}

.modal-body {
  padding: 16px 0;

  &.tabs {
    padding: 0 0 16px 0;
  }
}

.modal-overflow {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 16px 8px;

  &.tabs {
    height: 30vh;
  }
}

.modal-body .leaflet-container {
  position: relative;
  width: 100%;
  height: 300px;
  z-index: 5;
}

.modal-content {
  background-color: #ffffff;
  margin: auto;
  width: 850px;
  border-radius: 4px;
  padding: 32px;

  &.small {
    width: 360px;
  }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-title {
  font-size: 18px !important;
  color: #222222;
  margin: 0px 0px 0px 0px !important;
}

.modal-details-items {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border-bottom: 1px solid rgb(207, 207, 207);
}

.modal-details-items>.items {
  display: flex;
  flex-direction: column;
  width: calc(25% - 16px);
  padding: 8px;
}

.modal-details-items>.items>p {
  margin: 0 0 12px 0;
  font-size: 14px;
  word-wrap: break-word;
}

.modal-details-items>.items>p:first-child {
  font-weight: bold;
}

.modal-details-items>.items>p:first-child.not-bold {
  font-weight: 300;
}

.modal-details-items-title {
  font-size: 24px;
  margin: 16px 0 12px 0;
}

@media only screen and (max-width: 768px) {
  .modal-details-items>.items {
    width: 50%;
  }
}

.modal-details-container:not(:first-child) {
  display: none;
}

.order-details-modal-tabs {
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  padding-top: 32px;
}

.order-details-modal-tabs>div {
  width: 33.2%;
  border-bottom: 3px solid #c4c4c4;
  padding: 8px 0;
  color: #c4c4c4;
  cursor: pointer;

  &.active {
    color: #000;
    border-bottom: 3px solid #facd48;
  }
}

.signature-image {
  width: 100%;
  height: auto;
}

// ColoredText
.colored-text {
  border-radius: 4px;
  padding: 6px;
  color: #fff;
  text-align: center;
  display: inline-block;
}

.colored-text p {
  width: auto;
  font-size: 12px;
  font-weight: 500;
}

// Dropdown
.dropdown-container {
  position: relative;
}

.dropdown-container-checkbox {
  position: absolute;
  bottom: 38px;
  left: calc(50% - 15px);
}

.dropdown {
  width: auto;
  background-color: #ddd;
  color: #fff;
  position: absolute;
  right: calc(100% - 30px);
  top: 32px;
  padding: 6px 0;
  z-index: 1;

  &.right {
    right: auto;
  }

  &.up {
    top: auto;
    bottom: 32px;
  }
}

.header-dropdown {
  width: 100%;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  position: absolute;
  right: 0;
  top: 60px;
  padding: 6px 0;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
}

.dropdown ul,
.header-dropdown ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
  width: 100%;
}

.dropdown ul li,
.header-dropdown ul li,
.header-dropdown ul a {
  padding: 10px 6px;
  font-size: 14px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
  color: black;
  width: calc(100% - 12px);
  display: block;
}

.status-update {
  background-color: rgb(255, 107, 107);
  color: #fff !important;
}

.status-update:hover {
  background-color: rgb(180, 74, 74) !important;
}

.dropdown ul li:hover {
  background-color: rgb(189, 189, 189);
}

.header-dropdown ul li:hover,
.header-dropdown ul a:hover {
  background-color: rgb(228, 228, 228);
}

.dropdownLink {
  text-decoration: none;
  color: #fff;
}

@media screen and (max-width: 1500px) {
  .list-cell.client>p:nth-child(3)>span:nth-child(2) {
    font-size: 11px;
  }
}

@media screen and (max-width: 1023px) {
  .credentials {
    width: 80%;
    padding-top: 40px;
  }

  .sidebar-container {
    display: none;
  }

  .sidebar-controller {
    display: none !important;
  }

  .pages {
    width: 100%;
    padding-left: 0;
  }

  .mobile-sidebar {
    display: block;
    position: absolute;
    top: 26px;
    left: 18px;
    z-index: 2;
  }

  .logo {
    margin-left: 50px;
  }

  .radiowrapper {
    border: none;
    padding: 8px;
    border-radius: 2px;
  }

  .radio {
    display: flex;
    justify-content: space-between;
    background: url("../../images/mobile-menu.png") no-repeat center center / cover;
    width: 24px;
    height: 24px;
  }

  .radiocontent {
    width: 270px;
    display: flex;
    flex-flow: column nowrap;
    font-size: 20px;
    padding: 10px;
    margin-top: 10px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 0 1px 1px rgb(0 0 0 / 15%);
    color: rgb(146, 110, 42);
  }

  .list {
    overflow-x: scroll;
    padding-top: 24px;
  }

  .list-item {
    min-width: 1024px;
  }

  .permissions-container {
    width: 100%;
  }

  .permissions-container>.permission-cell>span {
    font-size: 14px;
  }

  .new-user-section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .new-user-button {
    width: calc(100% - 20px);
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 767px) {
  .mr-16 {
    margin-right: 0 !important;
  }

  .viewmode-container {
    display: none;
  }

  .deliveries-legend {
    margin-left: 0;
  }

  .filter-button {
    padding: 12px 12px;
  }

  .pagination-li {
    display: none;
  }

  .pagination {
    justify-content: space-between;
    margin-top: 16px;
  }

  .pagination-prev-button {
    width: 100%;
    border-radius: 4px 0px 0px 4px;
    padding: 0;
  }

  .pagination-next-button {
    width: 100%;
    background-color: #ed3837;
    color: #ffffff;
    border-radius: 0px 4px 4px 0px;
  }

  .list-container {
    padding: 16px 0 0 0;
  }

  .list-footer {
    flex-direction: column;
  }

  .pagination-disabled {
    display: none;
  }

  .pagination-prev-button:active {
    background-color: white;
    color: #f73931;
    border: 1px solid #3f3f4123;
  }

  .reactSelect {
    width: 100% !important;
    margin-right: 0;
  }

  .reactSelect__control {
    width: 100% !important;
    margin-right: 0 !important;
  }

  .input,
  .custom-select {
    width: 100% !important;
    margin-right: 0 !important;
  }

  .settings-name {
    display: none;
  }

  .new-order-inputs>div>.d-flex.column>div,
  .new-order-section {
    flex-wrap: wrap;
    width: 100% !important;
  }

  .new-order-inputs>div {
    flex-wrap: wrap;
  }

  .new-order-inputs>div>.d-flex.column>div>.input,
  .new-order-inputs>div>.input,
  .new-order-inputs>div>.d-flex>.input {
    margin-bottom: 20px !important;
  }

  .new-order-inputs>div>.d-flex {
    flex-wrap: wrap;
  }

  .new-user-section>.input,
  .new-user-section>.custom-select {
    width: 100% !important;
    margin-right: 0 !important;
  }

  .new-user-button {
    width: 100%;
  }

  .register-form {
    width: 80%;
    margin-bottom: 0;
  }

  .new-order-section>.new-order-inputs.address {
    padding-left: 0;
    border-left: none;
  }

  .new-order-section>.new-order-inputs.address>.input {
    margin-bottom: 20px !important;
  }

  .react-datepicker-wrapper {
    width: calc(50% - 24px) !important;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .datepicker-text {
    margin-left: 12px;
    margin-right: 12px;
  }

  .double-filters-container {
    width: 100%;
  }

  .modal-content {
    width: 90%;
    padding: 16px;
  }

  .file-uploader {
    margin: 12px 0 32px 0;
    background: #00a9a8;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 4px;
    color: #fff;
    padding: 10px 16px;
    position: relative;
    text-align: center;
    width: 140px;
    cursor: pointer;
    font-family: "Roboto";
    font-weight: 500;
    box-shadow: 0px 2px 2px #00000029;
  }

  .file-uploader input.file {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    width: 100%;
    height: 39px;
    cursor: pointer;
  }

  .file-uploader span {
    font-size: 13px;
  }

  .file-name {
    width: calc(100vw - 235px);
  }

  .list-cell.client>p:nth-child(3)>span:nth-child(2) {
    font-size: 12px;
  }

  .list-orders {
    width: 1300px;
  }
}

@media screen and (max-width: 426px) {
  .pages-container {
    padding: 32px 24px 24px 24px;
    width: calc(100% - 48px);
  }

  .empty-image {
    width: 209px;
    height: 148px;
  }

  .new-user-section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .new-user-button {
    width: calc(100% - 20px);
    display: flex;
    justify-content: flex-end;
  }
}

.scripting-orders-container {
  display: flex;
  align-items: center;
  padding: 14px;
}

.scripting-orders-container:nth-child(even) {
  background-color: rgb(247, 247, 247);
}

.scripting-orders-container:nth-child(odd) {
  background-color: rgb(239, 239, 239);
}

.secondary-items {
  margin: 0px !important;
  width: calc(100% - 22px)
}

.tracker-link {
  text-decoration: none;
  color: #000;
  border-radius: 6px;
  background-color: #facd48;
  padding: 6px;
}

.dev-warn {
  position: absolute;
  z-index: 1000;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #facd48;
  display: flex;
  justify-content: center;
}

.dev-warn>p {
  margin: 10px 0;
}

// Express Order
.express-content {
  width: 100%;
  display: flex;
}

.critical-item:after {
  content: '';
  position: absolute;
  top: 0;
  left: -1px;
  height: 100%;
  width: 9px;
  background-color: rgb(255, 80, 80);
  border-radius: 2px;
}

.alert-item:after {
  content: '';
  position: absolute;
  top: 0;
  left: -1px;
  height: 100%;
  width: 9px;
  background-color: rgb(255, 255, 80);
  border-radius: 2px;
}

.express-item {
  background-color: rgba(255, 0, 0, 0.09) !important;
  background-image: linear-gradient(to left, #f7f7f7, rgba(255, 0, 0, 0.09)) !important;
}

.drive-item {
  background-color: rgba(129, 129, 252, 0.09) !important;
  background-image: linear-gradient(to left, #f7f7f7, rgba(0, 0, 255, 0.09)) !important;
}

.post-item {
  background-color: rgba(255, 81, 0, 0.09) !important;
  background-image: linear-gradient(to left, #f7f7f7, rgba(255, 81, 0, 0.09)) !important;
}

.locker-item {
  background-color: rgba(0, 212, 0, 0.09) !important;
  background-image: linear-gradient(to left, #f7f7f7, rgba(0, 128, 0, 0.09)) !important;
}

.mile-item {
  background-color: rgba(0, 255, 179, 0.09) !important;
  background-image: linear-gradient(to left, #f7f7f7, rgba(0, 255, 179, 0.09)) !important;
}

.reverse-item {
  background-color: rgba(255, 255, 0, 0.09) !important;
  background-image: linear-gradient(to left, #f7f7f7, rgba(255, 255, 0, 0.09)) !important;
}

.peg-item {
  background-color: rgba(128, 0, 128, 0.09) !important;
  background-image: linear-gradient(to left, #f7f7f7, rgba(128, 0, 128, 0.09)) !important;
}

.clock {
  position: relative;
  height: 28px;
  width: 28px;
  background-color: #E25050;
  border-radius: 50%;
  margin-left: 12px;
}

.rotator {
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: #C4C4C4;
  border-radius: 200px 0 0 200px;
  transform-origin: 100% 50%;
  transition: transform 1s linear;
}

.mask {
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: #E25050;
  border-radius: 200px 0 0 200px;
}

.mask.mask-cover {
  background-color: #C4C4C4;
  border-radius: 0 200px 200px 0;
  right: 0;
}

.tooltip-container {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.tooltip {
  opacity: 0;
  z-index: 99;
  color: white;
  width: 190px;
  display: block;
  font-size: 13px;
  padding: 5px;
  border-radius: 3px;
  text-align: center;
  text-shadow: 1px 1px 2px #111;
  background: rgba(51, 51, 51, 0.9);
  border: 1px solid rgba(34, 34, 34, 0.9);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  position: absolute;
  right: -90px;
  bottom: 42px;
}

.tooltip:before,
.tooltip:after {
  content: '';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(51, 51, 51, 0.9);
  position: absolute;
  bottom: -10px;
  left: 43%;
}

.tooltip-container:hover .tooltip,
a:hover .tooltip {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}