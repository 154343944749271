.svg-orders {
	background: url("svg/sprite.css-ef6e74d4.svg") 0 0 no-repeat;
}

.svg-orders-dims {
	width: 24px;
	height: 24px;
}

.svg-orders-active {
	background: url("svg/sprite.css-ef6e74d4.svg") 50% 0 no-repeat;
}

.svg-orders-active-dims {
	width: 24px;
	height: 24px;
}

.svg-seller {
	background: url("svg/sprite.css-ef6e74d4.svg") 0 50% no-repeat;
}

.svg-seller-dims {
	width: 24px;
	height: 24px;
}

.svg-seller-active {
	background: url("svg/sprite.css-ef6e74d4.svg") 50% 50% no-repeat;
}

.svg-seller-active-dims {
	width: 24px;
	height: 24px;
}

.svg-transfers {
	background: url("svg/sprite.css-ef6e74d4.svg") 100% 0 no-repeat;
}

.svg-transfers-dims {
	width: 24px;
	height: 24px;
}

.svg-transfers-active {
	background: url("svg/sprite.css-ef6e74d4.svg") 100% 50% no-repeat;
}

.svg-transfers-active-dims {
	width: 24px;
	height: 24px;
}

.svg-users {
	background: url("svg/sprite.css-ef6e74d4.svg") 0 100% no-repeat;
}

.svg-users-dims {
	width: 24px;
	height: 24px;
}

.svg-users-active {
	background: url("svg/sprite.css-ef6e74d4.svg") 50% 100% no-repeat;
}

.svg-users-active-dims {
	width: 24px;
	height: 24px;
}

